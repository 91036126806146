const useReadNotification = () => {
  const { loading, mutate } = useReadNotificationDto();

  const readNotification = async (variables: ReadNotificationMutationVariables) => {
    await mutate(variables);
  };

  return {
    loading,
    readNotification,
  };
};

export { useReadNotification };
