<template>
  <div class="notification__modal-content">
    <div class="notification__header">
      <p class="typography__title1">Уведомления</p>

      <BaseButton2
        v-if="notifications.length > 0"
        class="ui-link-secondary ui-link-small"
        :disabled="loading"
        @click="() => readAllNotifications()"
      >
        Все прочитано
      </BaseButton2>

      <BaseButton2
        class="notification__modal-button-close"
        @click="() => closeModal()"
      >
        <BaseIcon2
          id="icon-cross"
          height="16"
          width="16"
        />
      </BaseButton2>
    </div>

    <ul
      v-if="notifications.length > 0"
      class="notification__list"
    >
      <li
        v-for="notification in notifications"
        :key="notification.id"
        class="notification__item"
      >
        <div class="notification__item-header">
          <p
            class="item__header"
            :class="{ active: !notification.viewed_at }"
          >
            {{ notification.title }}
          </p>

          <BaseLink
            v-if="notification.button_link"
            class="ui-link-secondary ui-link-small"
            href="#"
            @click="() => goToButtonLink(notification)"
          >
            {{ notification.button_text }}
          </BaseLink>
        </div>

        <p class="notification__item-footer typography__label1">
          {{ notification.project?.name }},

          <time>{{ date(notification.created_at).format("D MMMM YYYY") }}</time>
        </p>
      </li>
    </ul>

    <p
      v-else
      class="notification__item"
    >
      Новых уведомлений нет
    </p>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  notifications: Notification[];
}

const props = defineProps<Props>();

const emits = defineEmits<{
  readAllNotifications: [];
}>();

const { closeModal } = useModalStore();
const { loading, readNotification } = useReadNotification();

const readAllNotifications = async () => {
  const idsToUpdate = props.notifications
    .filter((notification) => {
      return !notification.viewed_at;
    })
    .map((notification) => {
      return notification.id;
    });

  if (idsToUpdate.length === 0) {
    emits("readAllNotifications");

    return;
  }

  await readNotification({
    ids: idsToUpdate,
  });

  emits("readAllNotifications");
};

const goToButtonLink = async (notification: NotificationFieldsFragment) => {
  await readNotification({
    ids: [notification.id],
  });

  window.open(notification.button_link, "_self");
};
</script>

<style scoped>
.notification__modal-button-close {
  width: 40px;
  height: 40px;
  color: var(--grey-3);
}

@media (--desktop) {
  .notification__modal-button-close {
    display: none;
  }
}

.notification__modal-content {
  display: flex;
  flex-direction: column;
}

.notification__header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 4px 8px 16px;
  border-bottom: 1px solid var(--grey-6);
  background-color: var(--white);
}

@media (--desktop) {
  .notification__header {
    padding: 12px 16px;
  }
}

.notification__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  padding: 8px 16px;
}

@media (--hover) {
  .notification__item:hover,
  .notification__item:focus-visible {
    background-color: var(--grey-7);
  }
}

.notification__item:not(:last-of-type) {
  border-bottom: 1px solid var(--grey-6);
}

.notification__item-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
}

.item__header {
  position: relative;
}

.active.item__header::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: var(--orange-1);
}

.notification__item-footer {
  color: var(--grey-2);
}
</style>
