<template>
  <div class="header__notification">
    <LazyBaseDropdown2
      id="user-notification"
      ref="userNotificationDropdown"
    >
      <template #reference>
        <BaseButton2 class="header__notification-button header__notification-button--desktop">
          <div
            class="header__notification-bell"
            :class="{ active: isActiveNotification }"
          >
            <BaseIcon2
              id="icon-bell"
              height="24"
              width="24"
            />
          </div>

          <BaseIcon2
            id="icon-cap"
            class="header__notification-caret"
            height="16"
            width="16"
          />
        </BaseButton2>
      </template>

      <template #content>
        <div class="base-dropdown-first notification__dropdown typography__body2 ui-scrollbar-primary">
          <TheHeaderCabinetUserNotificationContent
            :notifications="notificationList"
            @read-all-notifications="() => reloadNotifications()"
          />
        </div>
      </template>
    </LazyBaseDropdown2>

    <LazyBaseModalButton
      class="header__notification-button header__notification-button--mobile"
      :modal="MODAL.HeaderNotification"
    >
      <div
        class="header__notification-bell"
        :class="{ active: isActiveNotification }"
      >
        <BaseIcon2
          id="icon-bell"
          height="24"
          width="24"
        />
      </div>

      <BaseIcon2
        id="icon-cap"
        class="header__notification-caret"
        height="16"
        width="16"
      />
    </LazyBaseModalButton>

    <LazyBaseModal
      v-if="isModalOpen(MODAL.HeaderNotification)"
      :id="MODAL.HeaderNotification"
      class="base-overlay-first notification__modal ui-modal-primary"
      :is-show-button-close="false"
    >
      <div class="typography__body2">
        <TheHeaderCabinetUserNotificationContent
          :notifications="notificationList"
          @read-all-notifications="() => reloadNotifications()"
        />
      </div>
    </LazyBaseModal>
  </div>
</template>

<script lang="ts" setup>
const { isDesktop } = useBrowserBreakpoints();
const { closeModal, isModalOpen } = useModalStore();
let refetchNotificationsInterval: null | ReturnType<typeof setTimeout> = null;
const userNotificationDropdown = ref<HTMLElement[] | null>(null);

const { list: notificationList, refetch: notificationListRefetch } = await useGetNotificationListDto(
  {},
  {
    fetchPolicy: "no-cache",
  },
);

const isActiveNotification = computed(() => {
  return notificationList.value?.some((notification) => {
    return notification?.viewed_at === null;
  });
});

const reloadNotifications = async () => {
  await notificationListRefetch();

  if (userNotificationDropdown.value) {
    userNotificationDropdown.value.closeDropdown();
  }

  closeModal();
};

onMounted(() => {
  refetchNotificationsInterval = setInterval(() => {
    void notificationListRefetch();
  }, UPDATE_NOTIFICATION_SECONDS);
});

onUnmounted(() => {
  if (refetchNotificationsInterval) {
    clearInterval(refetchNotificationsInterval);
  }
});

watch(isDesktop, () => {
  closeModal();
});
</script>

<style scoped>
.header__notification {
  margin-left: auto;
}

.header__notification-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
}

@media (--desktop) {
  .header__notification-button {
    display: flex;
    column-gap: 8px;
    width: 72px;
    height: 60px;
    padding: 12px;
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
}

@media (--hover) {
  .header__notification:hover,
  .header__notification:focus-visible {
    background-color: var(--grey-7);
  }
}

@media (--desktop) {
  .header__notification-button--mobile {
    display: none;
  }
}

.header__notification-button--desktop {
  display: none;
}

@media (--desktop) {
  .header__notification-button--desktop {
    display: flex;
  }
}

.header__notification-bell {
  position: relative;
  width: 24px;
  height: 24px;
  color: var(--grey-3);
}

.header__notification-bell.active {
  color: var(--orange-1);
}

.header__notification-bell::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 4px;
  scale: 0;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: var(--orange-1);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.active.header__notification-bell::after {
  scale: 1;
}

.header__notification-caret {
  display: none;
  color: var(--grey-3);
}

@media (--desktop) {
  .header__notification-caret {
    rotate: 90deg;
    display: block;
  }
}

.notification__dropdown {
  width: 400px;
  max-height: 600px;
  overflow-y: auto;
}

:global(.notification__modal .c-modal__content) {
  padding: 0;
}
</style>
