<template>
  <div class="header-tools__wrapper">
    <BaseButton2
      class="header__burger"
      @click="() => openMenu()"
    >
      <BaseIcon2
        id="icon-burger"
        height="24"
        width="24"
      />
    </BaseButton2>

    <BaseLink
      class="header__logo-wrapper"
      href="/client/projects"
    >
      <img
        alt=""
        class="header__logo"
        src="/logos/logo.svg"
      />
    </BaseLink>

    <BaseLink
      class="header__help ui-link-secondary"
      href="/faq"
    >
      Нужна помощь?
    </BaseLink>
  </div>
</template>

<script lang="ts" setup>
const { toggleMenuCabinet } = useAppStore();

const openMenu = () => {
  toggleMenuCabinet(true);
  pageScrollBlock();
};
</script>

<style scoped>
.header-tools__wrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  column-gap: 8px;
}

@media (--desktop) {
  .header-tools__wrapper {
    column-gap: 40px;
  }
}

.header__logo-wrapper {
  line-height: 0;
}

.header__logo {
  width: 83px;
  height: 16px;
}

@media (--desktop) {
  .header__logo {
    width: 104px;
    height: 20px;
  }
}

.header__help {
  display: none;
}

@media (--desktop) {
  .header__help {
    display: block;
    flex-shrink: 0;
  }
}

.header__burger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  color: var(--orange-1);
}

@media (--desktop) {
  .header__burger {
    display: none;
  }
}
</style>
