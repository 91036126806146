const useGetNotificationListDto = async (
  variables?: GraphqlQueryVariables<GetNotificationListQueryVariables>,
  options?: GraphqlQueryOptions<GetNotificationListQuery, GetNotificationListQueryVariables>,
) => {
  const loadingLoadMore = ref(false);
  const query = await useGraphqlQuery(GetNotificationListSchema, variables, options);

  const list = computed(() => {
    return query.result.value?.content?.notifications?.list ?? null;
  });

  const meta = computed(() => {
    return query.result.value?.content?.notifications?.meta ?? null;
  });

  const loadMore = async (newVariables: Partial<GetNotificationListQueryVariables>) => {
    loadingLoadMore.value = true;

    await query.fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        return mergeData(previousQueryResult, fetchMoreResult);
      },
      variables: newVariables,
    });

    loadingLoadMore.value = false;
  };

  return {
    ...query,
    list,
    loadingLoadMore,
    loadMore,
    meta,
  };
};

export { useGetNotificationListDto };
